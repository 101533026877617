<template>
  <div class="page m-page">
    <p
      style="
        text-align: center;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑; font-weight: bold"
        >苗仓使用条款及支付协议</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑">&#xa0;</span>
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑; font-weight: bold">特别提示</span>
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >中山苗仓供应链管理有限公司（以下简称“苗仓”）在此特别提醒您（用户）在注册成为用户之前，请认真阅读《苗仓使用条款及支付协议》（以下简称“本协议”），确保您充分理解本协议中各条款。本协议仅适用于苗仓向您提供的所有产品即服务，包括苗仓微信小程序、移动客户端（即苗仓APP）及其它端（以下简称“本软件”）。</span
      ><span
        style="
          font-family: 微软雅黑;
          font-weight: bold;
          text-decoration: underline;
        "
        >请您审慎阅读并选择接受或不接受本协议。除非您接受本协议所有条款，否则您无权注册、登录或使用本协议所涉服务。您的注册、登录、使用等行为将视为对本协议的接受，并同意接受本协议各项条款的约束。</span
      ><span style="font-family: 微软雅黑"
        >您在苗仓地被直采中心采购端小程序(下称“小程序”)上勾选同意本支付协议后，即成为本支付协议之授权人，该授权即刻发生效力。您如果不同意以下协议条款，请勿勾选同意，且不要进行后续操作。</span
      >
    </p>
    <p style="line-height: 150%; font-size: 12pt">
      <span style="font-family: 微软雅黑"
        >如果您不满18岁，务必请您的家长或其他法定监护人（合称“监护人”）阅读本使用条款，并在征得您监护人同意的前提下访问或使用苗仓应用、或通过苗仓应用购买我们的产品、使用我们的服务或参与我们的活动。
        如果您是未成年人的监护人，在未成年本人访问或使用苗仓应用、或通过苗仓应用购买我们的产品、使用我们的服务或参与我们的活动时，您应正确引导并予以监护。</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >本协议约定苗仓与用户之间关于苗仓所有产品软件服务（以下简称“服务”）的权利义务。“用户”是指注册、登录、使用本服务的个人。</span
      ><span style="font-family: 微软雅黑">&#xa0;</span
      ><span
        style="
          font-family: 微软雅黑;
          font-weight: bold;
          text-decoration: underline;
        "
        >本协议可由苗仓随时更新，更新后的协议条款一旦公布即代替原来的协议条款，恕不再另行通知，用户可在本网站查阅最新版协议条款。</span
      ><span style="font-family: 微软雅黑">&#xa0;</span
      ><span style="font-family: 微软雅黑"
        >在苗仓修改协议条款后，如果用户不接受修改后的条款，请立即停止使用苗仓提供的服务，用户继续使用苗仓提供的服务将被视为接受修改后的协议。</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span
        ><span style="font-family: 微软雅黑; font-weight: bold"
          ><span>一、</span></span
        ></span
      ><span style="font-family: 微软雅黑; font-weight: bold">支付协议</span>
    </p>
    <ol type="1" style="margin: 0pt; padding-left: 0pt">
      <li
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;        
          font-family: 微软雅黑;
          font-size: 12pt;
        "
      >
        <span>授权人兹授权苗仓通过第三方支付平台划扣服务费。</span>
      </li>
      <li
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;      
          font-family: 微软雅黑;
          font-size: 12pt;
        "
      >
        <span>服务费是指授权人通过本软件提交的订单上记载的总费用。</span>
      </li>
      <li
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;          
          font-family: 微软雅黑;
          font-size: 12pt;
        "
      >
        <span
          >在授权人成功提交订单后，苗仓依照本软件上公布的收费规则计算服务费用。授权人应在5分钟内根据页面指示完成支付。</span
        >
      </li>
      <li
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;        
          font-family: 微软雅黑;
          font-size: 12pt;
        "
      >
        <span
          >如因授权人在第三方支付平台中的支付账户被锁定、盗用、被往来银行拒绝或账户无效等，以致支付账户付款失败时，苗仓有权依据与授权人之消费账单要求授权人支付服务费。</span
        >
      </li>
      <li
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;          
          font-family: 微软雅黑;
          font-size: 12pt;
        "
      >
        <span>授权人如有冒用、盗用他人支付账户之行为，须自负法律责任。</span>
      </li>
      <li
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;         
          font-family: 微软雅黑;
          font-size: 12pt;
        "
      >
        <span
          >在使用本软件服务的过程中，包括但不限于注册、下单、支付、收货等，如授权人未遵从相关规则，则苗仓有权拒绝为授权人提供相关服务，且无需承担任何责任。因授权人的过错导致的任何损失由授权人承担，该等过错包括但不限于：不按照交易提示操作，未及时进行交易操作等。</span
        >
      </li>
      <li
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;         
          font-family: 微软雅黑;
          font-size: 12pt;
        "
      >
        <span
          >授权人同意其消费产生的服务费用以苗仓记录的数据为准，苗仓通过本软件告知授权人。授权人对服务费用有异议或发现扣款金额与应缴费用金额不符时，可及时与本软件的跟单员、在线客服或者热线电话联系，客服热线电话:400-838-6166。</span
        >
      </li>
    </ol>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span
        ><span style="font-family: 微软雅黑; font-weight: bold"
          ><span>二、</span></span
        ></span
      ><span style="font-family: 微软雅黑; font-weight: bold">软件更新</span>
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >为了改善用户体验、完善服务内容，苗仓将不断努力开发本软件的新功能，并为您不时提供本软件的更新(这些更新可能会采取软件替换、修改、功能强化、版本升级等形式)。</span
      ><span
        style="
          font-family: 微软雅黑;
          font-weight: bold;
          text-decoration: underline;
        "
        >为了保证本软件及服务的安全性和功能的一致性，苗仓有权不向您特别通知而对本软件进行更新，或者对本软件的部分功能效果进行改变或限制。本软件新版本发布后，苗仓不保证旧版软件继续可用，亦不保证继续就旧版本软件提供相应的客户服务，请您随时核对并下载最新版本。因您未及时更新软件所导致本软件的相关功能无法正常使用而给您造成的损失(如有)，苗仓将不承担任何责任。</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span
        ><span style="font-family: 微软雅黑; font-weight: bold"
          ><span>三、</span></span
        ></span
      ><span style="font-family: 微软雅黑; font-weight: bold">真实信息</span>
    </p>
    <p style="line-height: 150%; font-size: 12pt">
      <span style="font-family: 微软雅黑"
        >为使我们能为您提供及时、准确的服务的产品与服务，请您务必确认您向我们所提交的信息的完整性、真实性、有效性。必要时我们可能会要求您进行实名验证。一旦发现您的信息违反法律法规或不符合我们的业务规则的，我们可能会采取相关措施，例如停止为您提供产品或服务、中止或注销苗仓用户账户（如已注册成为苗仓用户）等。</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span
        ><span style="font-family: 微软雅黑; font-weight: bold"
          ><span>四、</span></span
        ></span
      ><span style="font-family: 微软雅黑; font-weight: bold">订单管理</span>
    </p>
    <p style="line-height: 150%; font-size: 12pt">
      <span style="font-family: 微软雅黑"
        >您根据本使用条款及相关业务规则，通过点击、确认或类似方式签署的电子协议、订单等，即视为您本人真实意愿并具有法律效力。</span
      >
    </p>
    <p style="line-height: 150%; font-size: 12pt">
      <span style="font-family: 微软雅黑"
        >您理解并同意：我们提供的服务或产品有赖于系统的准确运行及网络的及时响应。
        若出现系统或网络故障、出错等，导致订单或页面展示错误、不当得利等情形，您同意我们可以采取更正差错、扣划款项、订单回转等适当的纠正措施。</span
      >
    </p>
    <p style="line-height: 150%; font-size: 12pt">
      <span style="font-family: 微软雅黑"
        >在下列情况下，苗仓有权取消您的订单，且无须向您承担任何责任、赔偿或补偿；</span
      >
    </p>
    <p style="line-height: 150%; font-size: 12pt">
      <span style="font-family: 微软雅黑">1） 如苗仓已和您协商一致的；</span>
    </p>
    <p style="line-height: 150%; font-size: 12pt">
      <span style="font-family: 微软雅黑"
        >2）
        您填写的配送地址或配送信息不全（或存在错误），或我们无法将订单送达至您填写的配送地址的；</span
      >
    </p>
    <p style="line-height: 150%; font-size: 12pt">
      <span style="font-family: 微软雅黑"
        >3）
        您订购的产品数量超出正常消费需求的，或存在其他无法配送的情形的；</span
      >
    </p>
    <p style="line-height: 150%; font-size: 12pt">
      <span style="font-family: 微软雅黑"
        >4）
        您的订单被我们的内部安全系统识别为非正常普通订单或疑似欺诈订单的；</span
      >
    </p>
    <p style="line-height: 150%; font-size: 12pt">
      <span style="font-family: 微软雅黑"
        >5） 订单显失公平，或存在其他重大误解情形的；</span
      >
    </p>
    <p style="line-height: 150%; font-size: 12pt">
      <span style="font-family: 微软雅黑"
        >6） 冒充他人或盗用他人账户或身份来下单的；</span
      >
    </p>
    <p style="line-height: 150%; font-size: 12pt">
      <span style="font-family: 微软雅黑"
        >7） 您的订单来源于非苗仓应用渠道的；</span
      >
    </p>
    <p style="line-height: 150%; font-size: 12pt">
      <span style="font-family: 微软雅黑"
        >8）
        您的订单是为了将商品转售给其他第三方的，或您是以向第三方提供代下单服务为业的；</span
      >
    </p>
    <p style="line-height: 150%; font-size: 12pt">
      <span style="font-family: 微软雅黑"
        >9）
        因不可抗力（指我们不能预见、不能避免、不能克服的任何行为或事件，包括但不限于台风、地震、洪水、冰雹、火灾、爆炸、流行病等灾害事件，罢工、战争、骚乱、暴动、恐怖袭击或威胁等社会异常事件，以及网络传输延迟、数据交换堵塞、系统故障、遭受第三方攻击等电信、网络或系统问题）或其他苗仓无法控制的情形导致无法履行订单的；</span
      >
    </p>
    <p style="line-height: 150%; font-size: 12pt">
      <span style="font-family: 微软雅黑"
        >10）
        您在购买商品过程中有任何侵犯其他用户正当权益或破坏苗仓应用运营秩序的行为，或其他根据苗仓的合理判断认定属于不正当的行为；</span
      >
    </p>
    <p style="line-height: 150%; font-size: 12pt">
      <span style="font-family: 微软雅黑"
        >11）
        违反本使用条款，或违反与苗仓应用相关的其他条款、政策或规则，或发生违背公平和诚实信用原则的情形；以及其他法律法规允许取消订单的情形。</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span
        ><span style="font-family: 微软雅黑; font-weight: bold"
          ><span>五、</span></span
        ></span
      ><span style="font-family: 微软雅黑; font-weight: bold">帐号注册</span>
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >1、用户在使用本服务前需要注册一个本软件的账号。本软件的账号应当使用手机号码绑定注册，请用户使用尚未与本软件的账号绑定的手机号码，以及未被苗仓根据本协议封禁的手机号码注册本软件的账号。</span
      ><span style="font-family: 微软雅黑">&#xa0;</span
      ><span
        style="
          font-family: 微软雅黑;
          font-weight: bold;
          text-decoration: underline;
        "
        >苗仓可以根据用户需求或产品需要对帐号注册和绑定的方式进行变更，而无须事先通知用户。</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >2、苗仓系基于地理位置的软件产品，用户注册时应当授权苗仓公开及使用其地理位置信息方可成功注册本软件的账号。</span
      ><span style="font-family: 微软雅黑">&#xa0;</span
      ><span
        style="
          font-family: 微软雅黑;
          font-weight: bold;
          text-decoration: underline;
        "
        >故用户完成注册即表明用户同意苗仓提取、公开及使用用户的地理位置信息。</span
      ><span style="font-family: 微软雅黑">&#xa0;</span
      ><span style="font-family: 微软雅黑"
        >如用户需要终止向其他用户公开其地理位置信息，可自行设置为隐身状态。</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >3、鉴于本软件的账号的绑定注册方式，您同意苗仓在注册时将使用您提供的手机号码及/或自动提取您的手机号码及自动提取您的手机设备识别码等信息用于注册。您同意给予运营商授权，授权运营商有权自动提取您的手机号码进行认证并用于苗仓账号注册，您保证遵守运营商的相关服务条款，如运营商对您的手机号认证成功，则您的注册即完成。如您不同意对运营商的授权和/或服务条款或者是您的手机号认证失败，您可以手动修改运营商提取的手机号码，采取验证码方式进行注册登录。</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >4、在用户注册及使用本服务时，苗仓需要搜集能识别用户身份的个人信息以便苗仓可以在必要时联系用户，或为用户提供更好的使用体验。苗仓搜集的信息包括但不限于用户的姓名、性别、年龄、出生日期、身份证号、地址、学校情况、公司情况、所属行业、兴趣爱好、常出没的地方、个人说明；苗仓同意对这些信息的使用将受限于第三条用户个人隐私信息保护的约束。</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑">&#xa0;</span>
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span
        ><span style="font-family: 微软雅黑; font-weight: bold"
          ><span>六、</span></span
        ></span
      ><span style="font-family: 微软雅黑; font-weight: bold">服务内容</span>
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >1、本服务的具体内容由苗仓根据实际情况提供，包括但不限于授权用户通过其帐号进行信息发布、添加好友、加入群组、关注他人、发布留言。</span
      ><span style="font-family: 微软雅黑">&#xa0;</span
      ><span
        style="
          font-family: 微软雅黑;
          font-weight: bold;
          text-decoration: underline;
        "
        >苗仓可以对其提供的服务予以变更，且苗仓提供的服务内容可能随时变更；用户将会收到苗仓关于服务变更的通知。</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >2、苗仓提供的服务包含免费服务与收费服务。用户可以通过付费方式购买收费服务，具体方式为：用户通过微信支付、支付宝或其他苗仓平台提供的付费途径支付一定数额的人民币购买苗仓平台的虚拟货币——苗币，然后根据苗仓公布的资费标准以苗币购买用户欲使用的收费服务，从而获得收费服务使用权限。对于收费服务，苗仓会在用户使用之前给予用户明确的提示，只有用户根据提示确认其同意按照前述支付方式支付费用并完成了支付行为，用户才能使用该等收费服务。支付行为的完成以银行或第三方支付平台生成“支付已完成”的确认通知为准。</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑">&#xa0;</span>
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span
        ><span style="font-family: 微软雅黑; font-weight: bold"
          ><span>七、</span></span
        ></span
      ><span style="font-family: 微软雅黑; font-weight: bold"
        >用户个人信息保护</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >1、用户在注册帐号或使用本服务的过程中，可能需要填写或提交一些必要的个人信息，如法律法规、规章规范性文件（以下称“法律法规”）规定的需要填写的身份信息。</span
      ><span style="font-family: 微软雅黑">&#xa0;</span
      ><span
        style="
          font-family: 微软雅黑;
          font-weight: bold;
          text-decoration: underline;
        "
        >如用户提交的信息不完整或不符合法律法规的规定，则用户可能无法使用本服务或在使用本服务的过程中受到限制。</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >2、用户个人信息包括：1）用户自行提供的用户个人信息（如注册时填写的手机号码，电子邮件等个人信息，使用服务时提供的共享信息等）；2）其他方分享的用户个人信息；</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >3）苗仓为提供服务而合法收集的用户必要个人信息（如使用服务时系统自动采集的设备或软件信息，浏览历史信息，通讯时间信息等技术信息，用户开启定位功能并使用服务时的地理位置信息等）。其中个人隐私信息是指涉及用户个人身份或个人隐私的信息，比如，用户真实姓名、身份证号、手机号码、手机设备识别码、IP地址、用户聊天记录。非个人隐私信息是指用户对本服务的操作状态以及使用习惯等明确且客观反映在苗仓服务器端的基本记录信息、个人隐私信息范围外的其它普通信息，以及用户同意公开的上述隐私信息。</span
      ><span style="font-family: 微软雅黑">&#xa0;</span
      ><span
        style="
          font-family: 微软雅黑;
          font-weight: bold;
          text-decoration: underline;
        "
        >苗仓保证在取得用户书面同意的情况下收集、使用或公开用户的个人隐私信息，用户同意苗仓无需获得用户的另行确认与授权即可收集、使用或公开用户的非个人隐私信息。</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >3、尊重用户个人信息的私有性是苗仓的一贯制度，苗仓将采取技术措施和其他必要措施，确保用户个人信息安全，防止在本服务中收集的用户个人信息泄露、毁损或丢失。在发生前述情形或者苗仓发现存在发生前述情形的可能时，苗仓将及时采取补救措施并告知用户，用户如发现存在前述情形亦需立即与苗仓联系。</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑">4、</span
      ><span style="font-family: 微软雅黑">&#xa0;</span
      ><span
        style="
          font-family: 微软雅黑;
          font-weight: bold;
          text-decoration: underline;
        "
        >苗仓未经用户同意不向任何第三方公开、透露用户个人隐私信息。</span
      ><span style="font-family: 微软雅黑">&#xa0;</span
      ><span style="font-family: 微软雅黑">但以下特定情形除外：</span>
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >（1）苗仓根据法律法规规定或有权机关的指示提供用户的个人隐私信息；</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >（2）由于用户将其用户密码告知他人或与他人共享注册帐户与密码，由此导致的任何个人信息的泄漏，或其他非因苗仓原因导致的个人隐私信息的泄露；</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >（3）用户自行向第三方公开其个人隐私信息；</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >（4）用户与苗仓及合作单位之间就用户个人隐私信息的使用公开达成约定，苗仓因此向合作单位公开用户个人隐私信息；</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >（5）任何由于黑客攻击、电脑病毒侵入及其他不可抗力事件导致用户个人隐私信息的泄露；</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >（6）用户个人信息已经经过处理无法识别特定个人且不能复原。</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >5、用户同意苗仓可在以下事项中使用用户的个人隐私信息：</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >（1）苗仓向用户及时发送重要通知，如软件更新、本协议条款的变更；</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >（2）苗仓内部进行审计、数据分析和研究等，以改进苗仓的产品、服务和与用户之间的沟通；</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >（3）依本协议约定，苗仓管理、审查用户信息及进行处理措施；</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >（4）适用法律法规规定的其他事项。</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >除上述事项外，如未取得用户事先同意，苗仓不会将用户个人隐私信息使用于任何其他用途。</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑">6、</span
      ><span style="font-family: 微软雅黑">&#xa0;</span
      ><span
        style="
          font-family: 微软雅黑;
          font-weight: bold;
          text-decoration: underline;
        "
        >因苗仓提供的服务系基于地理位置提供的移动社交服务，用户确认，其地理位置信息为非个人隐私信息，用户成功注册本软件的账号视为确认授权苗仓提取、公开及使用用户的地理位置信息。</span
      ><span style="font-family: 微软雅黑">&#xa0;</span
      ><span style="font-family: 微软雅黑"
        >用户地理位置信息将作为用户公开资料之一，由苗仓向其他用户公开以便苗仓向用户提供基于地理位置的移动社交服务。如用户需要终止向其他用户公开其地理位置信息，可随时自行设置为隐身状态。</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >7、为了改善苗仓的技术和服务，向用户提供更好的服务体验，苗仓或可会自行收集使用或向第三方提供用户的非个人隐私信息。</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >8、苗仓保证在合法、正当与必要的原则下收集、使用或者公开用户个人信息且不会收集与提供的服务无关的用户个人信息。</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑">9、</span
      ><span style="font-family: 微软雅黑">&#xa0;</span
      ><span
        style="
          font-family: 微软雅黑;
          font-weight: bold;
          text-decoration: underline;
        "
        >苗仓十分注重保护用户的个人隐私，并制定了《苗仓隐私政策》，用户确认并同意使用苗仓提供的服务将被视为接受《苗仓隐私政策》。</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑">&#xa0;</span>
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span
        ><span style="font-family: 微软雅黑; font-weight: bold"
          ><span>八、</span></span
        ></span
      ><span style="font-family: 微软雅黑; font-weight: bold">内容规范</span>
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >1、本条所述内容是指用户使用本服务过程中所制作、上载、复制、发布、传播的任何内容，包括但不限于帐号头像、名称、用户说明等注册信息及认证资料，或文字、语音、图片、视频、图文等发送、回复或自动回复消息和相关链接页面，以及其他使用帐号或本服务所产生的内容。</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >2、用户不得利用本软件的账号或本服务制作、上载、复制、发布、传播如下法律、法规和政策禁止的内容：</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >（1）反对宪法所确定的基本原则的；</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >（2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑">（3）损害国家荣誉和利益的；</span>
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >（4）煽动民族仇恨、民族歧视，破坏民族团结的；</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >（5）破坏国家宗教政策，宣扬邪教和封建迷信的；</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >（6）散布谣言，扰乱社会秩序，破坏社会稳定的；</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >（7）散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >（8）侮辱或者诽谤他人，侵害他人合法权益的；</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >（9）不遵守法律法规底线、社会主义制度底线、国家利益底线、公民合法权益底线、社会公共秩序底线、道德风尚底线和信息真实性底线的“七条底线”要求的；</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >（10）含有法律、行政法规禁止的其他内容的信息。</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >3、用户不得利用本软件的账号或本服务制作、上载、复制、发布、传播如下干扰苗仓正常运营，以及侵犯其他用户或第三方合法权益的内容：</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑">（1）含有任何性或性暗示的；</span>
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >（2）含有辱骂、恐吓、威胁内容的；</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >（3）含有骚扰、垃圾广告、恶意信息、诱骗信息的；</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >（4）涉及他人隐私、个人信息或资料的；</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >（5）侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的；</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >（6）含有其他干扰本服务正常运营和侵犯其他用户或第三方合法权益内容的信息。</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑">&#xa0;</span>
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span
        ><span style="font-family: 微软雅黑; font-weight: bold"
          ><span>九、</span></span
        ></span
      ><span style="font-family: 微软雅黑; font-weight: bold">使用规则</span>
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑">1、</span
      ><span style="font-family: 微软雅黑">&#xa0;</span
      ><span
        style="
          font-family: 微软雅黑;
          font-weight: bold;
          text-decoration: underline;
        "
        >用户在本服务中或通过本服务所传送、发布的任何内容并不反映或代表，也不得被视为反映或代表苗仓的观点、立场或政策，苗仓对此不承担任何责任。</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >2、用户不得利用本软件的账号或本服务进行如下行为：</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >（1）提交、发布虚假信息，或盗用他人头像或资料，冒充、利用他人名义的；</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >（2）强制、诱导其他用户关注、点击链接页面或分享信息的；</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >（3）虚构事实、隐瞒真相以误导、欺骗他人的；</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >（4）利用技术手段批量建立虚假帐号的；</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >（5）利用本软件的账号或本服务从事任何违法犯罪活动的；</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >（6）制作、发布与以上行为相关的方法、工具，或对此类方法、工具进行运营或传播，无论这些行为是否为商业目的；</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >（7）其他违反法律法规规定、侵犯其他用户合法权益、干扰苗仓正常运营或苗仓未明示授权的行为。</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑">3、</span
      ><span style="font-family: 微软雅黑">&#xa0;</span
      ><span
        style="
          font-family: 微软雅黑;
          font-weight: bold;
          text-decoration: underline;
        "
        >用户须对利用本软件的账号或本服务传送信息的真实性、合法性、无害性、准确性、有效性等全权负责，与用户所传播的信息相关的任何法律责任由用户自行承担，与苗仓无关。如因此给苗仓或第三方造成损害的，用户应当依法予以赔偿。</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >4、苗仓提供的服务中可能包括广告，用户同意在使用过程中显示苗仓和第三方供应商、合作伙伴提供的广告。</span
      ><span style="font-family: 微软雅黑">&#xa0;</span
      ><span
        style="
          font-family: 微软雅黑;
          font-weight: bold;
          text-decoration: underline;
        "
        >除法律法规明确规定外，用户应自行对依该广告信息进行的交易负责，对用户因依该广告信息进行的交易或前述广告商提供的内容而遭受的损失或损害，苗仓不承担任何责任。</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >5、除非苗仓书面许可，用户不得从事下列任一行为：</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >（1）删除软件及其副本上关于著作权的信息；</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >（2）对软件进行反向工程、反向汇编、反向编译，或者以其他方式尝试发现软件的源代码；</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >（3）对苗仓拥有知识产权的内容进行使用、出租、出借、复制、修改、链接、转载、汇编、发表、出版、建立镜像站点等；</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >（4）对软件或者软件运行过程中释放到任何终端内存中的数据、软件运行过程中客户端与服务器端的交互数据，以及软件运行所必需的系统数据，进行复制、修改、增加、删除、挂接运行或创作任何衍生作品，形式包括但不限于使用插件、外挂或非经苗仓授权的第三方工具/服务接入软件和相关系统；</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >（5）通过修改或伪造软件运行中的指令、数据，增加、删减、变动软件的功能或运行效果，或者将用于上述用途的软件、方法进行运营或向公众传播，无论这些行为是否为商业目的；</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >（6）通过非苗仓开发、授权的第三方软件、插件、外挂、系统，登录或使用苗仓软件及服务，或制作、发布、传播非苗仓开发、授权的第三方软件、插件、外挂、系统。</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑">&#xa0;</span>
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span
        ><span style="font-family: 微软雅黑; font-weight: bold"
          ><span>十、</span></span
        ></span
      ><span style="font-family: 微软雅黑; font-weight: bold">虚拟货币</span>
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >1、苗仓将在苗仓平台发行虚拟货币，即苗币。苗币可用于购买苗仓平台的增值服务，包括但不限于推广及信息服务，除此外，不得用于其他任何用途。</span
      ><span style="font-family: 微软雅黑">&#xa0;</span
      ><span
        style="
          font-family: 微软雅黑;
          font-weight: bold;
          text-decoration: underline;
        "
        >该等增值服务的价格均以苗币为单位，具体价格信息将由苗仓自行决定并在相关服务页面上显示。</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >2、苗币和人民币的兑换比例依用户购买渠道的不同而有不同的兑换比例，具体兑换比例以用户购买苗币相关渠道服务页面显示为准。</span
      ><span style="font-family: 微软雅黑">&#xa0;</span
      ><span
        style="
          font-family: 微软雅黑;
          font-weight: bold;
          text-decoration: underline;
        "
        >苗仓有权根据运营情况随时变更上述兑换比例，并将在用户购买苗币相关渠道服务页面显示。</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >3、用户默认已开通苗币账户，可进行苗币购买（下称“充值”）和消费。用户可在用户页面查询到苗币余额、购买记录和消费记录。苗币相关信息将不作为公开信息。</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >4、用户可以通过微信支付、支付宝或其他苗仓平台提供的充值途径为苗币账户进行充值。</span
      ><span style="font-family: 微软雅黑">&#xa0;</span
      ><span
        style="
          font-family: 微软雅黑;
          font-weight: bold;
          text-decoration: underline;
        "
        >用户确认不会以非法方式或者使用非平台所指定的充值途径进行充值，如果用户违规使用非苗仓认可的充值途径非法充值/购买苗币，则苗仓不保证充值顺利或正确完成，若因此造成用户权益受损，苗仓将不会作出任何补偿或赔偿，苗仓同时保留随时终止用户本软件账号资格及使用各项充值服务的权利，并进行相应惩罚。</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >5、用户确认在进行充值前已经仔细确认过自己的账号并仔细选择了相关操作选项，若因用户自身输入账号错误、操作不当或不了解充值计费方式等因素造成充错账号、错选充值种类等情形而损害自身权益的，苗仓将不会作出任何补偿或赔偿。</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >6、用户确认，苗币一经充值成功，除法律法规明确规定外，在任何情况下不能兑换为法定货币，不能转让他人。除法律法规明确规定外，苗币账户充值完成后，苗仓不予退款。</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >7、用户确认，苗币只能用于购买苗仓平台上的各类增值服务，任何情况下不得与苗仓以外的第三方进行苗币交易，亦不得在除苗仓平台以外的第三方平台（如淘宝）上进行交易；如违反前述约定，造成用户或第三方任何损失，苗仓不负任何责任，且如苗仓有理由怀疑用户的苗币账户或使用情况有作弊或异常状况，苗仓将拒绝该用户使用苗币进行支付，直至按本协议约定采取相关封禁措施。</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑">8、</span
      ><span style="font-family: 微软雅黑">&#xa0;</span
      ><span
        style="
          font-family: 微软雅黑;
          font-weight: bold;
          text-decoration: underline;
        "
        >苗仓有权基于交易安全等方面的考虑不时设定或修改涉及交易的相关事项，包括但不限于交易限额、交易次数等。</span
      ><span style="font-family: 微软雅黑">&#xa0;</span
      ><span style="font-family: 微软雅黑"
        >用户了解并确认苗仓的前述设定或修改可能对用户的交易产生一定的不便，用户对此没有异议。</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >9、用户确认，除法律法规明确规定或本协议另有约定外，用户已购买的任何收费服务不能以任何理由退购（即退换成苗币或法定货币）或调换成其他服务。</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >10、因用户自身的原因导致苗仓无法提供苗币购买服务或提供苗币购买服务时发生任何错误而产生的任何损失或责任，由用户自行负责，苗仓不承担责任，包括但不限于：</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >（1）因用户的苗仓账号丢失、被封禁或冻结；</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >（2）用户将密码告知他人导致的财产损失；</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >（3）因用户绑定的第三方支付机构账户的原因导致的任何损失或责任；</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >（4）其他用户故意或者重大过失或者违反法律法规导致的财产损失。</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span
        style="
          font-family: 微软雅黑;
          font-weight: bold;
          text-decoration: underline;
        "
        >11、用户在使用苗仓提供的服务时，如出现违反国家法律法规、本协议约定或其他本平台对用户的管理规定的情形，苗仓有权暂时或永久封禁用户的账号。</span
      ><span style="font-family: 微软雅黑">&#xa0;</span
      ><span style="font-family: 微软雅黑"
        >账号封禁后至解禁（如有）前，用户账户上的剩余苗币将被暂时冻结或全部扣除，不可继续用于购买平台上的虚拟产品或服务，同时不予返还用户购买苗币时的现金价值。</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑">12、</span
      ><span style="font-family: 微软雅黑">&#xa0;</span
      ><span
        style="
          font-family: 微软雅黑;
          font-weight: bold;
          text-decoration: underline;
        "
        >用户确认并同意如用户主动注销账号，则用户已充值到账的苗币，会员权益等视为自动放弃，苗仓不予返还相应的现金价值，也不会作出任何补偿。</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑">&#xa0;</span>
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span
        ><span style="font-family: 微软雅黑; font-weight: bold"
          ><span>十一、</span></span
        ></span
      ><span style="font-family: 微软雅黑; font-weight: bold">账户管理</span>
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑">1、</span
      ><span style="font-family: 微软雅黑">&#xa0;</span
      ><span
        style="
          font-family: 微软雅黑;
          font-weight: bold;
          text-decoration: underline;
        "
        >本软件的账号的所有权归苗仓所有，用户完成申请注册手续后，获得本软件的账号的使用权，该使用权仅属于初始申请注册人，禁止赠与、借用、租用、转让或售卖。苗仓因经营需要，有权回收用户的本软件的账号。</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >2、用户可以通过：1）查看与编辑个人资料页，2）“设置”页面里的“账号与安全”页面来查询、更改苗仓帐户上的个人资料、注册信息及传送内容等，但需注意，删除或修改有关信息的同时也会删除胡修改用户储存在系统中的文字和图片，用户需承担该风险。</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >3、用户有责任妥善保管注册帐号信息及帐号密码的安全，因用户保管不善可能导致遭受盗号或密码失窃，责任由用户自行承担。用户需要对注册帐号以及密码下的行为承担法律责任。用户同意在任何情况下不使用其他用户的帐号或密码。在用户怀疑他人使用其帐号或密码时，用户同意立即通知苗仓。</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >4、用户应遵守本协议的各项条款，正确、适当地使用本服务，如因用户违反本协议中的任何条款，苗仓在通知用户后有权依据协议中断或终止对违约用户本软件的账号提供服务。</span
      ><span style="font-family: 微软雅黑">&#xa0;</span
      ><span
        style="
          font-family: 微软雅黑;
          font-weight: bold;
          text-decoration: underline;
        "
        >同时，苗仓保留在任何时候收回本软件的账号、用户名的权利。</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑">5、</span
      ><span style="font-family: 微软雅黑">&#xa0;</span
      ><span
        style="
          font-family: 微软雅黑;
          font-weight: bold;
          text-decoration: underline;
        "
        >如用户注册本软件的账号后一年不登录，通知用户后，苗仓可以收回该帐号，以免造成资源浪费，由此造成的不利后果由用户自行承担。</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >6、用户可以通过“设置”页面里的“账号与安全”页面来进行账号注销服务，用户确认注销账号是不可恢复的操作，用户应自行备份与本软件账号相关的信息和数据，用户确认操作之前与本软件账号相关的所有服务均已进行妥善处理。用户确认并同意注销账号后并不代表本本软件账号注销前的账号行为和相关责任得到豁免或减轻，如在注销期间，用户的账号被他人投诉、被国家机关调查或者正处于诉讼、仲裁程序中，苗仓有限自行终止用户的账号注销并无需另行得到用户的同意。</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑">&#xa0;</span>
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span
        ><span style="font-family: 微软雅黑; font-weight: bold"
          ><span>十二、</span></span
        ></span
      ><span style="font-family: 微软雅黑; font-weight: bold">数据储存</span>
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑">1、</span
      ><span style="font-family: 微软雅黑">&#xa0;</span
      ><span
        style="
          font-family: 微软雅黑;
          font-weight: bold;
          text-decoration: underline;
        "
        >苗仓不对用户在本服务中相关数据的删除或储存失败负责。</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >2、苗仓可以根据实际情况自行决定用户在本服务中数据的最长储存期限，并在服务器上为其分配数据最大存储空间等。用户可根据自己的需要自行备份本服务中的相关数据。</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >3、如用户停止使用本服务或本服务终止，苗仓可以从服务器上永久地删除用户的数据。本服务停止、终止后，苗仓没有义务向用户返还任何数据。</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑">&#xa0;</span>
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span
        ><span style="font-family: 微软雅黑; font-weight: bold"
          ><span>十三、</span></span
        ></span
      ><span style="font-family: 微软雅黑; font-weight: bold">风险承担</span>
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >1、用户理解并同意，苗仓仅为用户提供信息分享、传送及获取的平台，用户必须为自己注册帐号下的一切行为负责，包括用户所传送的任何内容以及由此产生的任何后果。用户应对苗仓及本服务中的内容自行加以判断，并承担因使用内容而引起的所有风险，包括因对内容的正确性、完整性或实用性的依赖而产生的风险。</span
      ><span style="font-family: 微软雅黑">&#xa0;</span
      ><span
        style="
          font-family: 微软雅黑;
          font-weight: bold;
          text-decoration: underline;
        "
        >苗仓无法且不会对因用户行为而导致的任何损失或损害承担责任。</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >如果用户发现任何人违反本协议约定或以其他不当的方式使用本服务，请立即向苗仓举报或投诉，苗仓将依本协议约定进行处理。</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >2、用户理解并同意，因业务发展需要，苗仓保留单方面对本服务的全部或部分服务内容变更、暂停、终止或撤销的权利，用户需承担此风险。</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑">&#xa0;</span>
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span
        ><span style="font-family: 微软雅黑; font-weight: bold"
          ><span>十四、</span></span
        ></span
      ><span style="font-family: 微软雅黑; font-weight: bold"
        >知识产权声明</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >1、除本服务中涉及广告的知识产权（包括但不限于专利权、著作权、商标权及商业秘密）由相应广告商享有外，苗仓在本服务中提供的内容（包括但不限于网页、文字、图片、音频、视频、图表、数据资料等）的知识产权均归苗仓所有，但用户在使用本服务前对自己发布的内容已合法取得知识产权的除外。</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >2、除另有特别声明外，苗仓提供本服务时所依托软件的著作权、专利权及其他知识产权均归苗仓所有。</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >3、苗仓在本服务中所涉及的图形、文字或其组成，以及其他苗仓标志及产品、服务名称（以下统称“苗仓标识”），其著作权或商标权归苗仓所有。</span
      ><span style="font-family: 微软雅黑">&#xa0;</span
      ><span
        style="
          font-family: 微软雅黑;
          font-weight: bold;
          text-decoration: underline;
        "
        >未经苗仓事先书面同意，用户不得将苗仓标识以任何方式展示或使用或作其他处理，也不得向他人表明用户有权展示、使用、或其他有权处理苗仓标识的行为。</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >4、上述及其他任何苗仓或相关广告商依法拥有的知识产权均受到法律保护，未经苗仓或相关广告商书面许可，用户不得以任何形式进行使用或创造相关衍生作品。</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >5、用户在使用本软件服务时发表上传的文字、图片、视频、音频、软件以及表演等信息，此部分信息的知识产权归用户，责任由用户承担。</span
      ><span style="font-family: 微软雅黑">&#xa0;</span
      ><span
        style="
          font-family: 微软雅黑;
          font-weight: bold;
          text-decoration: underline;
        "
        >但用户的发表、上传行为视为对苗仓的授权，用户理解并同意授予苗仓及其关联公司全球范围内完全免费、不可撤销、独家、永久、可转授权和可再许可的权利，包括但不限于：复制权、发行权、出租权、展览权、表演权、放映权、广播权、信息网络传播权、摄制权、改编权、翻译权、汇编权以及《著作权法》规定的由著作权人享有的其他著作财产权利及邻接权利。</span
      ><span style="font-family: 微软雅黑">&#xa0;</span
      ><span style="font-family: 微软雅黑"
        >苗仓可自行选择是否使用以及使用方式，包括但不限于将前述信息在苗仓旗下的服务平台上使用与传播，将上述信息再次编辑后使用，以及由苗仓授权给合作方使用、编辑与传播等。</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑">&#xa0;</span>
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span
        ><span style="font-family: 微软雅黑; font-weight: bold"
          ><span>十五、</span></span
        ></span
      ><span style="font-family: 微软雅黑; font-weight: bold">法律责任</span>
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >1、如果苗仓发现或收到他人举报或投诉用户违反本协议约定的，苗仓有权不经通知随时对相关内容，包括但不限于用户资料、聊天记录进行审查、删除，并视情节轻重对违规帐号处以包括但不限于警告、帐号封禁、设备封禁、功能封禁的处罚，且通知用户处理结果。</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >2、因违反用户协议被封禁的用户，可以自行到个人中心查询封禁期限，并在封禁期限届满后自助解封。其中，被实施功能封禁的用户会在封禁期届满后自动恢复被封禁功能。被封禁用户可向苗仓网站相关页面提交申诉，苗仓将对申诉进行审查，并自行合理判断决定是否变更处罚措施。</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >3、用户理解并同意，苗仓有权依合理判断对违反有关法律法规或本协议规定的行为进行处罚，对违法违规的任何用户采取适当的法律行动，并依据法律法规保存有关信息向有关部门报告等，用户应承担由此而产生的一切法律责任。</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >4、用户理解并同意，因用户违反本协议约定，导致或产生的任何第三方主张的任何索赔、要求或损失，包括合理的律师费，用户应当赔偿苗仓与合作公司、关联公司，并使之免受损害。</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑">&#xa0;</span>
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span
        ><span style="font-family: 微软雅黑; font-weight: bold"
          ><span>十六、</span></span
        ></span
      ><span style="font-family: 微软雅黑; font-weight: bold"
        >不可抗力及其他免责事由</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >1、用户理解并确认，在使用本服务的过程中，可能会遇到不可抗力等风险因素，使本服务发生中断。不可抗力是指不能预见、不能克服并不能避免且对一方或双方造成重大影响的客观事件，包括但不限于自然灾害如洪水、地震、瘟疫流行和风暴等以及社会事件如战争、动乱、政府行为等。</span
      ><span style="font-family: 微软雅黑">&#xa0;</span
      ><span
        style="
          font-family: 微软雅黑;
          font-weight: bold;
          text-decoration: underline;
        "
        >出现上述情况时，苗仓将努力在第一时间与相关单位配合，及时进行修复，但是由此给用户或第三方造成的损失，苗仓及合作单位在法律允许的范围内免责。</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >2、本服务同大多数互联网服务一样，受包括但不限于用户原因、网络服务质量、社会环境等因素的差异影响，可能受到各种安全问题的侵扰，如他人利用用户的资料，造成现实生活中的骚扰；用户下载安装的其它软件或访问的其他网站中含有“特洛伊木马”等病毒，威胁到用户的计算机信息和数据的安全，继而影响本服务的正常使用等等。用户应加强信息安全及使用者资料的保护意识，要注意加强密码保护，以免遭致损失和骚扰。</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >3、用户理解并确认，本服务存在因不可抗力、计算机病毒或黑客攻击、系统不稳定、用户所在位置、用户关机以及其他任何技术、互联网络、通信线路原因等造成的服务中断或不能满足用户要求的风险，因此导致的用户或第三方任何损失，苗仓不承担任何责任。</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑">4、</span
      ><span style="font-family: 微软雅黑">&#xa0;</span
      ><span
        style="
          font-family: 微软雅黑;
          font-weight: bold;
          text-decoration: underline;
        "
        >用户理解并确认，在使用本服务过程中存在来自任何他人的包括误导性的、欺骗性的、威胁性的、诽谤性的、令人反感的或非法的信息，或侵犯他人权利的匿名或冒名的信息，以及伴随该等信息的行为，因此导致的用户或第三方的任何损失，苗仓不承担任何责任。</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >5、用户理解并确认，苗仓需要定期或不定期地对苗仓平台或相关的设备进行检修或者维护，如因此类情况而造成服务在合理时间内的中断，苗仓无需为此承担任何责任，但苗仓应事先进行通告。</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >6、苗仓依据法律法规、本协议约定获得处理违法违规或违约内容的权利，该权利不构成苗仓的义务或承诺，苗仓不能保证及时发现违法违规或违约行为或进行相应处理。</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >7、用户理解并确认，对于苗仓向用户提供的下列产品或者服务的质量缺陷及其引发的任何损失，苗仓无需承担任何责任：</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑">（1）苗仓向用户免费提供的服务；</span>
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >（2）苗仓向用户赠送的任何产品或者服务。</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >8、在任何情况下，苗仓均不对任何间接性、后果性、惩罚性、偶然性、特殊性或刑罚性的损害，包括因用户使用苗仓或本服务而遭受的利润损失，承担责任（即使苗仓已被告知该等损失的可能性亦然）。尽管本协议中可能含有相悖的规定，苗仓对用户承担的全部责任，无论因何原因或何种行为方式，始终不超过用户因使用苗仓提供的服务而支付给苗仓的费用（如有）。</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑">&#xa0;</span>
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span
        ><span style="font-family: 微软雅黑; font-weight: bold"
          ><span>十七、</span></span
        ></span
      ><span style="font-family: 微软雅黑; font-weight: bold"
        >服务的变更、中断、终止</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑">1、</span
      ><span style="font-family: 微软雅黑">&#xa0;</span
      ><span
        style="
          font-family: 微软雅黑;
          font-weight: bold;
          text-decoration: underline;
        "
        >鉴于网络服务的特殊性，用户同意苗仓有权随时变更、中断或终止部分或全部的服务（包括收费服务）。</span
      ><span style="font-family: 微软雅黑">&#xa0;</span
      ><span style="font-family: 微软雅黑"
        >苗仓变更、中断或终止的服务，苗仓应当在变更、中断或终止之前通知用户，并应向受影响的用户提供等值的替代性的服务；如用户不愿意接受替代性的服务，如果该用户已经向苗仓支付的苗币，苗仓应当按照该用户实际使用服务的情况扣除相应苗币之后将剩余的苗币退还用户的苗币账户中。</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >2、如发生下列任何一种情形，苗仓有权变更、中断或终止向用户提供的免费服务或收费服务，而无需对用户或任何第三方承担任何责任：</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >（1）根据法律规定用户应提交真实信息，而用户提供的个人资料不真实、或与注册时信息不一致又未能提供合理证明；</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >（2）用户违反相关法律法规或本协议的约定；</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >（3）按照法律规定或有权机关的要求；</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >（4）出于安全的原因或其他必要的情形。</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑">&#xa0;</span>
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span
        ><span style="font-family: 微软雅黑; font-weight: bold"
          ><span>十八、</span></span
        ></span
      ><span style="font-family: 微软雅黑; font-weight: bold">其他</span>
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >1、苗仓郑重提醒用户注意本协议中免除苗仓责任和限制用户权利的条款，请用户仔细阅读，自主考虑风险。</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >2、本协议的效力、解释及纠纷的解决，适用于中华人民共和国法律。若用户和苗仓之间发生任何纠纷或争议，首先应友好协商解决，协商不成的，用户同意将纠纷或争议提交广东省中山市有管辖权的人民法院管辖。</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >3、本协议的任何条款无论因何种原因无效或不具可执行性，其余条款仍有效，对双方具有约束力。</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >4、由于互联网高速发展，您与苗仓签署的本协议列明的条款可能并不能完整罗列并覆盖您与苗仓所有权利与义务，现有的约定也不能保证完全符合未来发展的需求。因此，苗仓隐私政策等均为本协议的补充协议，与本协议不可分割且具有同等法律效力。如您使用苗仓平台服务，视为您同意上述补充协议。</span
      >
    </p>
  </div>
</template>

<script>
export default {
  name: "Terms",
};
</script>

<style>
body {
  text-align: justify;
  widows: 0;
  orphans: 0;
  font-family: Calibri;
  font-size: 10.5pt;
}
p {
  margin: 0pt;
}
li {
  margin-top: 0pt;
  margin-bottom: 0pt;
}
.Header {
  text-align: justify;
  line-height: normal;
  widows: 0;
  orphans: 0;
  font-family: Calibri;
  font-size: 9pt;
}
.page {
  box-sizing: border-box;
  width: 100%;
  padding: 16px;
  word-break: break-all;
}
</style>
